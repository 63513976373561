import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import { Section } from "../components/Section"
import { Card } from "../components/Card"

const BlogIndex = ({ data, location }) => {
  const siteMetadata = data.site.siteMetadata
  const siteTitle = siteMetadata?.title || `Title`
  const sections = data.allTaxon.nodes

  if (sections?.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title={siteTitle} />
        <p>
          Статей нет
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={siteTitle} />
      <Card
        type="POSTER"
        aspectRatio={4.2}
        className="mb-5 d-flex justify-content-center"
        picture={siteMetadata.imgSet}
      >
        <div className="container h-100">
          <div className="hero-wireframe">
            <div className="hero-text">
              <div
                className="initial-letter markdown"
                dangerouslySetInnerHTML={{ __html: siteMetadata.description }}
              />
            </div>
          </div>
        </div>
      </Card>
      <div className="container">
        {
          sections.map((value) => (
            <Section
              key={value.id}
              id={value.id}
              headline={value.label}
              type={value.layouts?.preview?.kind}
              limit={value.layouts?.preview?.limit}
              moreSlug={(value.posts.hasMore) ? `/taxa/${value.id}`: null}
              cardType={value.layouts?.preview?.cardType}
              className="mb-5"
              posts={value.posts.list}
            />
          ))
        }
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        imgSet {
          src
          media
        }
      }
    }
    allTaxon(sort: {order: ASC, fields: [order]}) {
      nodes {
        id
        label
        posts {
          list {
            id
            title
            timeUpdate
            logLine
            poster
            tags {
              id
              label
            }
          }
          hasMore
        }
        layouts {
          preview {
            kind
            limit
            cardType
          }
        }
      }
    }
  }
`