import React from "react"
import { Card } from "../Card"
import { Pagination, Proportion } from "@sivasifr/ui-carousel/Pagination"
import { Button } from "@sivasifr/ui-core/Button"
import { Carousel, CarouselContextProvider } from "@sivasifr/ui-carousel/Carousel"
import { IconSvg } from '@sivasifr/icons/IconSvg'
import { Typography, Vignette } from '@sivasifr/ui-core/Typography'
import Link from '@sivasifr/ui-core/Link'
import styles from './Section.module.css'

const limitToProportion = {
  '1': 12,
  '2': 6,
  '3': 4,
}

const Section = ({ id, headline, type, limit, className, moreSlug, cardType, posts }) => {

  if (posts?.length === 0) {
    return (
      <p>
        No blog post found. Add to database
      </p>
    )
  }

  const LinkComponent = moreSlug ? Link : 'span'
  const linkProps = (moreSlug && { href: moreSlug, color: "accent" })

  return (
    <section className={className}>
      {
        type === 'TILES' && (
          <div className="row">
            <div className="col-md-3">
              <Typography
                component="h2"
                size="xhg"
                font="display"
                className="font-display"
              >
                <LinkComponent {...linkProps}>
                  {headline}&nbsp;
                  {moreSlug && (<IconSvg name="arrow-left" />)}
                </LinkComponent>
                <br/>
                <Vignette />
              </Typography>
            </div>
            <div className="col-md-9">
              <div className="row">
                {posts.map((post) => {
                  return (
                    <div key={post.id} className={`col-lg-${limitToProportion[limit]}`}>
                      <Card
                        {...post}
                        slug={`/article/${post.id.toLowerCase().replace(/[.,\/#!$%&*;:{}=_`~()]/g,'')}`}
                        type={cardType}
                        {...(cardType === 'POSTER' && {
                          bg: `50% 50%/cover url(${post.poster})`,
                          aspectRatio: 9/16,
                        })}
                        {...(cardType === 'DEFAULT' && {
                          bg: `#FFFFFF`,
                        })}
                        alignCenter={cardType === 'POSTER'}
                      >
                        {
                          id !== 'movies' && (
                            <div className="d-flex align-items-center justify-content-center h-100 p-3">
                              <Typography
                                component="h2"
                                itemProp="headline"
                              >
                                {post.title}
                              </Typography>
                            </div>
                          )
                        }
                      </Card>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )
      }
      {
        type === 'CAROUSEL' && (
          <CarouselContextProvider>
            <div className="row">
              <div className="col-md-3">
                <Typography
                  component="h2"
                  size="xhg"
                  font="display"
                  className="font-display"
                >
                  <LinkComponent {...linkProps}>
                    {headline}&nbsp;
                    {moreSlug && (<IconSvg name="arrow-left" />)}
                  </LinkComponent>
                  <br/>
                  <Vignette />
                </Typography>
              </div>
              <div className="col-md-9">
                <div
                  className="row g-0"
                  style={{background: 'hsl(var(--huePrimary),var(--saturationPrimary),var(--lightnessPrimary))'}}
                >
                  <div className="col-lg-9">
                    <Carousel containerWidth={400}>
                      {
                        posts.map((post) => (
                          <div key={post.id}>
                            <Card

                              type={cardType}
                              {...post}
                              slug={`/article/${post.id.toLowerCase().replace(/[.,\/#!$%&*;:{}=_`~()]/g,'')}`}
                              {...(cardType === 'FLYER' && {
                                aspectRatio: 20 / 9,
                                bg: 'hsl(var(--huePrimary),var(--saturationPrimary),var(--lightnessPrimary))',
                              })}
                            />
                          </div>
                        ))
                      }
                    </Carousel>
                  </div>
                  <div className="col-lg-3 d-flex flex-column p-3">
                    <Pagination
                      className={styles.paginationLayout}
                      arrowNext={<Button><IconSvg name="arrow-left" /></Button>}
                      arrowPrev={<Button><IconSvg name="arrow-right" /></Button>}
                    />
                    <Proportion classNameNum={styles.numerator} />
                  </div>
                </div>
              </div>
            </div>
          </CarouselContextProvider>
        )
      }
    </section>
  )
}

export default Section